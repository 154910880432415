<template>
  <nav
    v-if="location != 'login'"
    :class="[whiteColor]"
    class="menu text-black text-center"
  >
    <!-- logo -->
    <div>
      <router-link :to="{ name: 'home' }">
        <img
          :src="location == 'home' ? whiteLogo : blackLogo"
          alt="Roman Parubochyi Photography"
          class="max-w-xs mt-10 mb-5 px-5 md:px-0 md:my-10 mx-auto"
        />
      </router-link>
    </div>

    <div class="hidden md:flex justify-center items-center">
      <router-link
        :to="i.path"
        class="mx-10 uppercase text-sm"
        v-for="i in menu"
        :key="i.path"
        exact-path
      >
        {{ i.name }}
      </router-link>
    </div>

    <!-- mobile button -->
    <div class="md:hidden flex flex-col" v-click-outside="vcoConfig">
      <div class="relative sm:max-w-xl mx-auto" @click="mobileMenu = !mobileMenu">
        <button
          :class="[whiteColor]"
          class="text-black w-10 h-10 relative focus:outline-none"
        >
          <span class="sr-only">menu</span>
          <div
            class="block w-5 absolute left-1/2 top-1/2   transform  -translate-x-1/2 -translate-y-1/2"
          >
            <span
              aria-hidden="true"
              class="block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out"
              :class="{
                'rotate-45': mobileMenu,
                ' -translate-y-1.5': !mobileMenu,
              }"
            ></span>
            <span
              aria-hidden="true"
              class="block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out"
              :class="{ 'opacity-0': mobileMenu }"
            ></span>
            <span
              aria-hidden="true"
              class="block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out"
              :class="{
                '-rotate-45': mobileMenu,
                ' translate-y-1.5': !mobileMenu,
              }"
            ></span>
          </div>
        </button>
        <!-- mobile menu -->
        <ul class="mobile-menu" :class="{checked: mobileMenu}">
          <li
            v-for="i in menu"
            :key="i.path"
            @click.stop="mobileMenu = false"
          >
            <router-link
              :to="i.path"
              class="block py-5 mx-10 uppercase text-sm"
              exact-path
            >
              {{ i.name }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Menu",
  props: ["location"],
  data() {
    return {
      visible: true,
      menu: [
        { path: "/", name: "home" },
        { path: "/portfolio", name: "portfolio" },
        { path: "/about", name: "about  me" },
        { path: "/contacts", name: "contacts" },
      ],
      whiteLogo: require("../assets/logo2.png"),
      blackLogo: require("../assets/logo1.png"),
      mobileMenu: false,
      vcoConfig: {
        handler: this.close,
        middleware: this.middleware,
        events: ["dblclick", "click"]
      }
    };
  },
  computed: {
    whiteColor() {
      return {
        "text-white": this.location == "home",
      };
    },
  },
  watch: {
    mobileMenu(newVal, oldVal) {
      if (newVal) document.documentElement.style.overflow = "hidden"
      else document.documentElement.style.overflow = "auto"
    }
  },
  methods: {
    close(e) {
      this.mobileMenu = false
    },
    middleware(e) {
      return true
    },
  },
};
</script>

<style lang="sass" scoped>
$ease: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275)
.menu
  a
    font-weight: 300
    &.router-link-exact-active
      text-decoration: none
      font-weight: 700
    &.router-link-active
      @extend .router-link-exact-active
.mobile-menu
  background: rgba(#fff, .7)
  color: #000
  position: fixed
  left: 0
  right: 0
  transform: translate(0, -100vw)
  transition: $ease
  z-index: 9999
.checked
  transform: translate(0, 0)
  padding: 20px 0 40px
</style>
