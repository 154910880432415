<template>
  <div
    v-if="location != 'login'"
    :class="[homeFooter]"
    class="flex flex-col footer text-center light-gray pb-1 md:pb-5 font-raleway"
  >
    <hr v-if="location != 'home'" class="border-black mx-3 lg:mx-8 pb-4" /> 
    <div class="flex flex-row justify-center font-raleway">
      <a
        href="https://www.instagram.com/roman_parubochyi_photo/"
        class="px-1 md:px-7 capitalize flex justify-center items-center"
        target="_blank"
      >
        <svg :class="whiteColor" class="w-5 h-5 md:w-6 md:h-6 m-2.5" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
          />
        </svg>
        <span>Instagram</span>
      </a>
      <a
        href="https://www.facebook.com/roman.parubochyi"
        class="px-1 md:px-7 capitalize flex justify-center items-center"
        target="_blank"
      >
        <svg :class="whiteColor" class="w-5 h-5 md:w-6 md:h-6 m-2.5" viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z"
          />
        </svg>
        <span>Facebook</span>
      </a>
    </div>
    <div class="py-2 md:pt-6 text-s md:text-xs">© {{ curentYear }}</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: ["location"],
  data() {
    return {
      curentYear: new Date().getFullYear(),
    };
  },
  computed: {
    homeFooter() {
      return {
        'text-white': this.location == 'home',
        'absolute bottom-4 md:bottom-2 left-0 right-0': this.location == 'home'
      };
    },
    whiteColor() {
      return {
        'text-white': this.location == 'home',
      }
    }
  },
};
</script>

<style lang="postcss" scoped>
.footer{
  letter-spacing: 0.1em;
  line-height: 18px;
  font-size: 12px;
}
</style>
