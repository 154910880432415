import {db, auth} from '@/source/firebase'

import router from '../router'

export default {
  state: {
    userProfile: {}
  },
  
  getters: {
  
  },
  
  mutations: {
    setUserProfile(state, user) {
      console.log(user)
      state.userProfile = user
    }
  },
  
  actions: {
    async login({dispatch}, authData) {
      const { user } = await auth.signInWithEmailAndPassword(authData.email, authData.password)
      
      console.log(user)
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile ({commit}, user) {
      const userProfile = await db.collection('users').doc(user.uid).get()
  
      commit('setUserProfile', userProfile.data())
      router.push('/admin')
    },
    async logout({ commit }) {
      await auth.signOut()
      commit('setUserProfile', {})
      router.currentRoute.path !== '/login' && router.push('/login');
    },
  }
}