import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyBBHqX1GSjyjXiElU4fAm4sIWvGCFweiz8",
  authDomain: "photography-68227.firebaseapp.com",
  databaseURL: "https://photography-68227.firebaseio.com",
  projectId: "photography-68227",
  storageBucket: "photography-68227.appspot.com",
  messagingSenderId: "917499936789",
  appId: "1:917499936789:web:7b863fd0c5b30255330e1c",
  measurementId: "G-8P71F35YPJ"
};

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const timeStamp = firebase.firestore.Timestamp.now();

export {
  db, auth, storage, timeStamp
}