import {db, storage, timeStamp} from '@/source/firebase'

const state = {
  categories: [],
  uploading: 0
}

const getters = {
  categories: state => state.categories,
  uploading: state => state.uploading
}

const mutations = {
  setCategories: (state, categories) => {
    state.categories = categories
  },
  addCategory: (state, category) => {
    state.categories.push(category)
  },
  removeCategory: (state, cat) => {
    state.categories.splice(state.categories.indexOf(cat), 1)
  },
  uploading: (state, percentage) => state.uploading = percentage,
}

const actions = {
  async getCategories ({commit}) {
    const collectionRef = await db.collection('categories')
      .orderBy('createdAt', 'desc')

    collectionRef.get().then( snap => {
      let res = []
      snap.docs.forEach(doc => {
        res.push({id: doc.id, ...doc.data()})
      })
      commit('setCategories', res)
    })
  },
  async addCategory ({commit}, {categoryName, file}) {
    const storageRef = storage.ref(`categoryImages/${categoryName}/${file.name}`)
    // upload image
    storageRef.put(file).on('state_changed', (s) => {
      let percentage = (s.bytesTransferred / s.totalBytes) * 100
      commit('uploading', percentage)
    }, err => {
      console.error(err)
    }, async () => {
      const fUrl = await storageRef.getDownloadURL()
      const createdAt = timeStamp
      let data = {
        name: categoryName,
        image: fUrl,
        createdAt
      }
      await db.collection('categories').add(data)
      commit('addCategory', data)
    })
  },
  // async editCategory ({commit}, category) {
  // },
  async deleteCategory ({commit}, cat) {
    commit('removeCategory', cat)
    await storage.refFromURL(cat.image).delete()
    await db.collection('categories').doc(cat.id).delete()
    console.log('category deleted successfully')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}