<template>
  <div class="main">
    <Menu :location="$route.name" />
    <transition name="fade" mode="out-in">
      <router-view class="flex-grow" />
    </transition>
    <Footer class="flex-grow-0" :location="$route.name" />
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
export default {
  components: {
    Menu,
    Footer,
  },
};
</script>

<style lang="scss">
.main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    min-height: -webkit-fill-available;
  }
}
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .5s ease;
}

.fade-leave-active {
  transition: opacity .5s ease;
  opacity: 0;
}
</style>
