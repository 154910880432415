import {db, storage, timeStamp} from '@/source/firebase'

const state = {
  photoCollections: [],
  photoUploading: 0,
  photos: [],
}

const getters = {
  photoCollections: state => state.photoCollections,
  photoUploading: state => state.photoUploading,
  isLoading: state => state.loading,

  photos: state => state.photos,
}

const mutations = {
  setPhotoCollections: (state, collections) => {
    state.photoCollections = collections
  },
  addPhotoCollection: (state, collection) => {
    state.photoCollections.push(collection)
  },
  removeCollection: (state, cat) => {
    state.photoCollections.splice(state.photoCollections.indexOf(cat), 1)
  },

  photoUploading: (state, percentage) => state.photoUploading = percentage,

  setPhotos: (state, photos) => {
    state.photos = photos
  },
  addPhotos: (state, photos) => {
    state.photos.push(photos)
  },
  removePhoto: (state, photo) => {
    state.photos.splice(state.photos.indexOf(photo), 1)
  }
}

const actions = {
  async getCollections ({commit}, categoryId) {
    const collectionRef = await db.collection('categories')
      .doc(categoryId)
      .collection('photoCollections')
      .orderBy('createdAt', 'desc')

    collectionRef.get().then( snap => {
      let res = []
      snap.docs.forEach(doc => {
        res.push({id: doc.id, ...doc.data()})
      })
      commit('setPhotoCollections', res)
    })
  },

  async addPhotoCollection ({commit}, collection) {
    const collectionName = collection.name.toLowerCase()
    const storageRef = storage.ref(`photo-collections/${collectionName}/bg-${collection.image.name}`);
    // upload image
    storageRef.put(collection.image).on('state_changed', (s) => {
      let progress = (s.bytesTransferred / s.totalBytes) * 100
      commit('photoUploading', progress)
    }, err => {
      console.error(err)
    }, async () => {
      const fUrl = await storageRef.getDownloadURL()

      const collectionData = {
        name: collection.name,
        category: collection.categoryId,
        location: collection.location,
        createdAt: timeStamp,
        image: fUrl,
      }
      const categoryId = collectionData.category

      await db.collection('categories')
        .doc(categoryId)
        .collection('photoCollections')
        .add(collectionData)

      commit('addPhotoCollection', collectionData)
    })
  },

  async deleteCollection ({commit}, collection) {
    commit('removeCollection', collection)
    await storage.refFromURL(collection.image).delete()

    await db.collection('categories')
      .doc(collection.category)
      .collection('photoCollections')
      .doc(collection.id)
      .delete().then(() => {
        console.log("Collection successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });
  },
  
  async getPhotos ({commit}, {collectionId, categoryId}) {
    const ref = await db.collection('categories')
      .doc(categoryId)
      .collection('photoCollections')
      .doc(collectionId)
      .collection('photos')
      .orderBy('createdAt', 'desc')

      ref.get().then( snap => {
        let res = []
        snap.docs.forEach(doc => {
          res.push({id: doc.id, ...doc.data()})
        })
        commit('setPhotos', res)
      })
  },
  
  async addPhotosToCollection ({commit}, data) {
    try {
      console.log(data)
      const images = data.images
      const collectionName = data.collectionName.toLowerCase()

      if (images.length > 0){
        images.forEach(async image => {
          const storageRef = storage.ref(`photo-collections/${collectionName}/${image.name}`);
          // upload image
          storageRef.put(image).on('state_changed', (s) => {
            let progress = (s.bytesTransferred / s.totalBytes) * 100
            commit('photoUploading', progress)
          }, err => {
            console.error(err)
          }, async () => {
            const fUrl = await storageRef.getDownloadURL()
            const imageData = {
              createdAt: timeStamp,
              image: fUrl
            }

            await db.collection('categories')
              .doc(data.categoryId)
              .collection('photoCollections')
              .doc(data.collectionId)
              .collection('photos')
              .add(imageData)

            commit('addPhotos', imageData)
          })
        });
      }

    } catch (error) {
      console.log(error)
    }
  },

  async deletePhoto ({commit}, photo) {
    commit('removePhoto', photo)
    await storage.refFromURL(photo.image).delete()
    await db.collection('categories')
      .doc(photo.categoryId)
      .collection('photoCollections')
      .doc(photo.collectionId)
      .collection('photos')
      .doc(photo.id)
      .delete()
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}