import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/source/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('../views/Contacts.vue'),
    meta: {
      layout: 'main'
    }
  },
  {
    path: '/portfolio',
    component: () => import('../views/Portfolio.vue'),
    meta: {
      layout: 'main'
    },
    children: [
      {
        path: '',
        name: 'categoriesLayout',
        component: () => import('@/components/CategoryCard.vue'),
        meta: {
          layout: 'main'
        }
      },
      {
        path: ':categoryId',
        component: {
          render: (h) => h("div", [h("router-view")]),
        },
        meta: {
          layout: 'main'
        },
        children: [
          {
            path: '',
            name: 'categoryCollections',
            component: () => import('@/components/CollectionCard.vue'),
            meta: {
              layout: 'main'
            },
          },
          {
            path: 'collection/:collectionId',
            name: 'photoGrid',
            component: () => import('@/components/ImageGrid.vue'),
            meta: {
              layout: 'main'
            },
          }
        ]
      }
    ]
  },
  
  {
    path: '/admin',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        name: 'categories',
        component: () => import('../views/admin/Category.vue'),
      },
      {
        path: ':categoryId',
        component: {
          render: (h) => h("div", [h("router-view")]),
        },
        children: [
          {
            path: '',
            name: 'photoCollection',
            component: () => import('../views/admin/PhotoCollections.vue'),
          },
          {
            path: 'collection/:id',
            name: 'photos',
            component: () => import('../views/admin/Photos.vue'),
          }
        ]
      },
    ],
    meta: {
      requiresAuth: true,
      layout: 'empty'
    },
  },

  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/Login.vue'),
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import( '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
